<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Список не нумерованный</div>
      </template>

      <div>
        <ul>
          <li>Lorem ipsum dolor.</li>
          <li>Culpa, quae quam.</li>
          <li>Cum, id voluptatibus.</li>
          <li>Alias non, officiis.</li>
          <li>Assumenda necessitatibus, officia!</li>
        </ul>
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Список нумерованный</div>
      </template>

      <div>
        <ol>
          <li>Lorem ipsum dolor.</li>
          <li>Culpa, quae quam.</li>
          <li>Cum, id voluptatibus.</li>
          <li>Alias non, officiis.</li>
          <li>Assumenda necessitatibus, officia!</li>
        </ol>
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Lists',
  components: { UISection },
}
</script>
