var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Таблица")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "Date", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Name", width: "180" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", label: "Address" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }