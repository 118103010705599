<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Дропдаун</div>
      </template>

      <div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            Открыть дропдаун ссылка
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>Action 1</el-dropdown-item>
            <el-dropdown-item>Action 2</el-dropdown-item>
            <el-dropdown-item>Action 3</el-dropdown-item>
            <el-dropdown-item disabled>Action 4</el-dropdown-item>
            <el-dropdown-item divided>Action 5</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="m-l-10" trigger="click">
          <el-button type="primary">
            Открыть дропдаун кнопка
            <i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>Action 1</el-dropdown-item>
            <el-dropdown-item>Action 2</el-dropdown-item>
            <el-dropdown-item>Action 3</el-dropdown-item>
            <el-dropdown-item>Action 4</el-dropdown-item>
            <el-dropdown-item>Action 5</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Dropdowns',
  components: { UISection },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    showDialog(visible) {
      this.setDialog({ name: 'test-dialog', visible })
    },
  },
}
</script>
