var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ui" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "ui__container" }, [
        _c("div", { staticClass: "ui__menu-wrap" }, [
          _c(
            "div",
            { staticClass: "ui__menu" },
            [
              _c("h2", { staticClass: "secondary" }, [_vm._v("UI компоненты")]),
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-vertical-demo",
                  attrs: { "default-active": _vm.activeItemMenu },
                  on: { select: _vm.handleSelect },
                },
                _vm._l(_vm.contentUI, function (item) {
                  return _c(
                    "el-menu-item",
                    { key: item.id, attrs: { index: String(item.id) } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ]),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "ui__body" },
          _vm._l(_vm.contentUI, function (item) {
            return _c(item.component, {
              key: item.id,
              ref: `section-${item.id}`,
              refInFor: true,
              tag: "Component",
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }