var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-headers ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [_vm._v("h1 Заголовок первого уровня (Основной)")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h1", [_vm._v("Заголовок первого уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h1 Заголовок первого уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h1", { staticClass: "secondary" }, [
              _vm._v("Заголовок первого уровня"),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [_vm._v("h2 Заголовок второго уровня (Основной)")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h2", [_vm._v("Заголовок второго уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h2 Заголовок второго уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h2", { staticClass: "secondary" }, [
              _vm._v("Заголовок второго уровня"),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h3 Заголовок третьего уровня (Основной)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h3", [_vm._v("Заголовок третьего уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h3 Заголовок третьего уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h3", { staticClass: "secondary" }, [
              _vm._v("Заголовок третьего уровня"),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h4 Заголовок чеетвертого уровня (Основной)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h4", [_vm._v("Заголовок чеетвертого уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h4 Заголовок чеетвертого уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h4", { staticClass: "secondary" }, [
              _vm._v("Заголовок чеетвертого уровня"),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [_vm._v("h5 Заголовок пятого уровня (Основной)")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h5", [_vm._v("Заголовок пятого уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h5 Заголовок пятого уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h5", { staticClass: "secondary" }, [
              _vm._v("Заголовок пятого уровня"),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [_vm._v("h6 Заголовок шестого уровня (Основной)")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", [_c("h6", [_vm._v("Заголовок шестого уровня")])])]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("h6 Заголовок шестого уровня (Вспомогательный)"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("h6", { staticClass: "secondary" }, [
              _vm._v("Заголовок шестого уровня"),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }