<template>
  <div class="ui">
    <div class="container">
      <div class="ui__container">
        <div class="ui__menu-wrap">
          <div class="ui__menu">
            <h2 class="secondary">UI компоненты</h2>
            <el-menu
              :default-active="activeItemMenu"
              class="el-menu-vertical-demo"
              @select="handleSelect"
            >
              <el-menu-item
                v-for="item in contentUI"
                :key="item.id"
                :index="String(item.id)"
              >
                <template slot="title">
                  <span>{{ item.title }}</span>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
        </div>

        <div class="ui__body">
          <Component
            :is="item.component"
            v-for="item in contentUI"
            :key="item.id"
            :ref="`section-${item.id}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contentUI } from '@/views/ui/data/ui'
import Buttons from '@/views/ui/components/Buttons'
import Collapse from '@/views/ui/components/Collapse'
import Colors from '@/views/ui/components/Colors'
import Dialogs from '@/views/ui/components/Dialogs'
import Dropdowns from '@/views/ui/components/Dropdowns'
import Headers from '@/views/ui/components/Headers'
import Inputs from '@/views/ui/components/Inputs'
import Lists from '@/views/ui/components/Lists'
import Paragraph from '@/views/ui/components/Paragraph'
import Tables from '@/views/ui/components/Tables'
export default {
  name: 'UI',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=1600, initial-scale=1',
      },
    ],
  },
  components: {
    Colors,
    Inputs,
    Paragraph,
    Headers,
    Buttons,
    Collapse,
    Dialogs,
    Dropdowns,
    Lists,
    Tables,
  },
  data() {
    return {
      contentUI,
      sectionsList: [],
      activeItemMenu: '1',
    }
  },
  mounted() {
    this.setPositionElements()
    document.addEventListener('scroll', this.checkActiveMenu)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.checkActiveMenu)
  },
  methods: {
    handleSelect(key) {
      this.sectionsList.forEach(item => {
        if (item.id === key) {
          window.scrollTo({
            top: item.top,
            behavior: 'smooth',
          })
        }
      })
    },
    checkActiveMenu() {
      const { scrollY } = window

      for (let key in this.sectionsList) {
        if (
          scrollY - 10 > this.sectionsList[key].top &&
          scrollY - 10 < this.sectionsList[key].bottom
        ) {
          this.activeItemMenu = this.sectionsList[key].id

          break
        }
      }
    },
    setPositionElements() {
      this.$nextTick(() => {
        const refs = this.$refs

        this.sectionsList = Object.keys(refs).map(item => ({
          top: refs[item][0].$el.getBoundingClientRect().top,
          bottom: refs[item][0].$el.getBoundingClientRect().bottom,
          id: item.split('-')[1],
        }))
      })
    },
  },
}
</script>

<style lang="sass">
.ui
  padding: 20px

  &__container
    display: flex
    justify-content: space-between
    align-items: stretch

  &__menu-wrap
    flex: 0 0 220px

  &__menu
    position: sticky
    top: 10px
    max-height: 100vh
    overflow: auto

  &__body
    padding: 16px 24px
    flex: 1 0 calc(100% - 220px)

  .el-menu
    border-right: none

.ui-parent-section
  padding: 21px
  background: #FFFFFF
  border: $border-default
  box-shadow: $box-shadow-default
  border-radius: 8px
  margin-bottom: 11px
</style>
