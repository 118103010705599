<template>
  <div class="ui-headers ui-parent-section">
    <UISection>
      <template #title>
        <div>h1 Заголовок первого уровня (Основной)</div>
      </template>

      <div><h1>Заголовок первого уровня</h1></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h1 Заголовок первого уровня (Вспомогательный)</div>
      </template>

      <div><h1 class="secondary">Заголовок первого уровня</h1></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h2 Заголовок второго уровня (Основной)</div>
      </template>

      <div><h2>Заголовок второго уровня</h2></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h2 Заголовок второго уровня (Вспомогательный)</div>
      </template>

      <div><h2 class="secondary">Заголовок второго уровня</h2></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h3 Заголовок третьего уровня (Основной)</div>
      </template>

      <div><h3>Заголовок третьего уровня</h3></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h3 Заголовок третьего уровня (Вспомогательный)</div>
      </template>

      <div><h3 class="secondary">Заголовок третьего уровня</h3></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h4 Заголовок чеетвертого уровня (Основной)</div>
      </template>

      <div><h4>Заголовок чеетвертого уровня</h4></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h4 Заголовок чеетвертого уровня (Вспомогательный)</div>
      </template>

      <div><h4 class="secondary">Заголовок чеетвертого уровня</h4></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h5 Заголовок пятого уровня (Основной)</div>
      </template>

      <div><h5>Заголовок пятого уровня</h5></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h5 Заголовок пятого уровня (Вспомогательный)</div>
      </template>

      <div><h5 class="secondary">Заголовок пятого уровня</h5></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h6 Заголовок шестого уровня (Основной)</div>
      </template>

      <div><h6>Заголовок шестого уровня</h6></div>
    </UISection>

    <UISection>
      <template #title>
        <div>h6 Заголовок шестого уровня (Вспомогательный)</div>
      </template>

      <div><h6 class="secondary">Заголовок шестого уровня</h6></div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Headers',
  components: { UISection },
}
</script>
