var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Дропдаун")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(" Открыть дропдаун ссылка "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", [_vm._v("Action 1")]),
                      _c("el-dropdown-item", [_vm._v("Action 2")]),
                      _c("el-dropdown-item", [_vm._v("Action 3")]),
                      _c("el-dropdown-item", { attrs: { disabled: "" } }, [
                        _vm._v("Action 4"),
                      ]),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v("Action 5"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown",
                { staticClass: "m-l-10", attrs: { trigger: "click" } },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v(" Открыть дропдаун кнопка "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", [_vm._v("Action 1")]),
                      _c("el-dropdown-item", [_vm._v("Action 2")]),
                      _c("el-dropdown-item", [_vm._v("Action 3")]),
                      _c("el-dropdown-item", [_vm._v("Action 4")]),
                      _c("el-dropdown-item", [_vm._v("Action 5")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }