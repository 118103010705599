var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Список не нумерованный")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("ul", [
              _c("li", [_vm._v("Lorem ipsum dolor.")]),
              _c("li", [_vm._v("Culpa, quae quam.")]),
              _c("li", [_vm._v("Cum, id voluptatibus.")]),
              _c("li", [_vm._v("Alias non, officiis.")]),
              _c("li", [_vm._v("Assumenda necessitatibus, officia!")]),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Список нумерованный")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("ol", [
              _c("li", [_vm._v("Lorem ipsum dolor.")]),
              _c("li", [_vm._v("Culpa, quae quam.")]),
              _c("li", [_vm._v("Cum, id voluptatibus.")]),
              _c("li", [_vm._v("Alias non, officiis.")]),
              _c("li", [_vm._v("Assumenda necessitatibus, officia!")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }