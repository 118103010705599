var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Параграф (Основной)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(
                " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dolore eligendi eos est eveniet excepturi modi natus, sed sunt tenetur? Esse est impedit numquam obcaecati odio odit possimus quam repellat. "
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Параграф (Вспомогательный)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _c("p", { staticClass: "secondary" }, [
              _vm._v(
                " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi dolore eligendi eos est eveniet excepturi modi natus, sed sunt tenetur? Esse est impedit numquam obcaecati odio odit possimus quam repellat. "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }