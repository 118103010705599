<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Параграф (Основной)</div>
      </template>

      <div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi
          dolore eligendi eos est eveniet excepturi modi natus, sed sunt
          tenetur? Esse est impedit numquam obcaecati odio odit possimus quam
          repellat.
        </p>
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Параграф (Вспомогательный)</div>
      </template>

      <div>
        <p class="secondary">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi
          dolore eligendi eos est eveniet excepturi modi natus, sed sunt
          tenetur? Esse est impedit numquam obcaecati odio odit possimus quam
          repellat.
        </p>
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Paragraph',
  components: { UISection },
}
</script>
